import React from 'react';
import Wrapper from '../components/Wrapper';
import Acnavbar from '../components/Acnavbar';
import Hero from '../components/Hero';
import Footer from '../components/footer';
import Seo from '../shared/seo';
import bugsworkfavicon from '../assets/images/favicon.png';
import nocode from '../json/nocodepage.json';
import Feature from '../components/Feature';
import Efficiency from '../components/Efficiency';

const NocodeAutomation = () => {
  return (
    <Wrapper>
      <Seo
        title="bugs.work"
        description="Move fast, stay aligned, and build better - together"
      />

      <Acnavbar
        home={true}
        pathname={'nocode-automation'}
        logo={bugsworkfavicon}
        logoLogic={false}
      />
      <Hero
        isScrollAnimationPresent={true}
        headerText="Automate your testing with bugs.work"
        subHeading="
        It's every product owner's nightmare: releasing new changes only to find they disrupt 
       existing functionalities. bugs.work is here to ensure that your apps perform flawlessly. 
Automate your test flows without any coding required, receive periodic test reports, and 
get instant notifications on failures. With bugs.work, you can rest assured that your 
updates are seamless and your app remains top-notch.
        "
      />
      {nocode.map((visual) => (
        <Feature
          img={visual.img}
          headerText={visual?.headerText}
          bulletPoints={visual?.bulletPoints}
          imagePosition={visual?.imagePosition}
          bgColor={visual?.bgColor}
        />
      ))}
      <Efficiency />
      <Footer pathname={'nocode-automation'} />
    </Wrapper>
  );
};

export default NocodeAutomation;
